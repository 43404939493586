import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import PhoneInput from 'react-phone-number-input'
import { Button } from 'primereact/button'
import './FormComponent.scss'
import ThankYouLetter from '../ThankYouLetter/ThankYouLetter'
import {  geo, registration } from '../../service/api'
import { getUtms } from '../../service/url'

let isGeo = false
const geoData = {}

if (!isGeo) {
    isGeo = true
    geo()
        .then(({ data }) => {
            geoData['city'] = data.city
            geoData['country'] = data.country_name
            geoData['timezone'] = 'UTC' + (new Date().getTimezoneOffset() > 0 ? '+' : '') + (new Date().getTimezoneOffset() / 60)
        })
        .catch((error) => console.log(error))
}

const Form = ({ data, setIsFormSubmitted }) => {
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('Пожалуйста, введите ваше имя')
    const [nameDirty, setNameDirty] = useState(false)

    const [email, setEmail] = useState('')
    const [emailDirty, setEmailDirty] = useState(false)
    const [emailError, setEmailError] = useState('Пожалуйста, введите ваш E-mail')

    const [phone, setPhone] = useState('')
    const [phoneDirty, setPhoneDirty] = useState(false)
    const [phoneError, setPhoneError] = useState('Пожалуйста, введите ваш номер телефона')

    const [isSubmit, setIsSubmit] = useState(false)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [registrationData, setRegistrationData] = useState(null)


    const validateName = (name) => {
        setName(name)
        if (name.length < 2) {
            setNameError('Имя должно быть длиннее 2-х символов')
            if (!name) {
                setNameError('Пожалуйста, введите ваше имя')
            }
        } else {
            setNameError('')
        }
    }

    const validateEmail = (email) => {
        setEmail(email)
        const isValid = String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        isValid ? setEmailError('') : setEmailError('Некорректный Email')
    }

    const validatePhone = (phone) => {
        setPhone(phone)
        if (phone?.length < 10 || !phone) {
            setPhoneError('Введен некорректный номер телефона')
            if (!phone) {
                setPhoneError('Пожалуйста, введите ваш номер телефона')
            }
        } else {
            setPhoneError('')
        }
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'name':
                setNameDirty(true)
                break
            case 'email':
                setEmailDirty(true)
                break
            case 'phone':
                setPhoneDirty(true)
                break
            default: break
        }
    }
    let url = new URL(window.location.href)


    let parentUrl = '';
    if(!!url.searchParams.get('parent_url')){
        const parentUrlParam = new URL(url.searchParams.get('parent_url'));
        parentUrl = parentUrlParam.protocol + '//' + parentUrlParam.host
    }
    
    // Отправка формы
    const registrationUser = () => {
        if (nameError || emailError || phoneError) {
            setNameDirty(true)
            setEmailDirty(true)
            setPhoneDirty(true)
        } else {
            setIsButtonLoading(true)
            // console.log(getUtms())
            const userData = { email, name, phone, config_id: data.id, ...getUtms(), ...geoData }

            registration(userData)
                .then((res) => {
                    try{
                        localStorage.setItem('__ash_reg', res.data.contact);
                        localStorage.setItem('__ash_reg_id', res.data.id);
                    } catch (e) {
                        //localStorage not working in Incognito
                    }
                    

                    if(url.searchParams.get('redirect')) {
                        if(url.searchParams.get('redirect_parent')){
                            if(url.searchParams.get('redirect').indexOf('http') > -1){
                                if(url.searchParams.get('redirect').indexOf('?') > -1){
                                    window.parent.location.href = url.searchParams.get('redirect') + '&email=' + email
                                } else {
                                    window.parent.location.href = url.searchParams.get('redirect') + '?email=' + email
                                }
                            } else {
                                window.parent.location.href = parentUrl + url.searchParams.get('redirect') + '?email=' + email
                            }
                        } else {
                            window.location.href = url.searchParams.get('redirect') + '?email=' + email
                        }
                        
                    } else {
                        setIsFormSubmitted(true)
                        setIsButtonLoading(false)
                        setIsSubmit(true)
                        setRegistrationData(res)
                    }
                })
                .catch((err) => {
                    setIsButtonLoading(false)
                    console.log(err)
                })
        }
    }

    return (
        <>
            {isSubmit && <ThankYouLetter data={data} registrationData={registrationData} />}
            {!isSubmit && (
                <div className={"pl-0 lg:pl-4"}>

                    <div className="booking-form">

                        <div className="field mb-3 mt-2">
                            <InputText required name="name" type="text" value={name} onChange={(e) => validateName(e.target.value)} onBlur={(e) => blurHandler(e)} className="w-full " placeholder="Ваше имя*" />
                            {nameDirty && nameError && <small className="p-error block">{nameError}</small>}
                        </div>
                        <div className="field mb-3">
                            <InputText required name="email" type="email" value={email} onChange={(e) => validateEmail(e.target.value)} onBlur={(e) => blurHandler(e)} className="w-full" placeholder="E-mail*" />
                            {emailDirty && emailError && <small className="p-error block">{emailError}</small>}
                        </div>
                        <div className="field mb-4">
                            <PhoneInput className="phone w-full" required name="phone" placeholder="Телефон*" value={phone} onChange={(e) => validatePhone(e)} onBlur={(e) => blurHandler(e)} />
                            {phoneDirty && phoneError && <small className="p-error block">{phoneError}</small>}
                        </div>

                        <Button label={'Записаться'} onClick={() => registrationUser()} loading={isButtonLoading} iconPos="left" />
                    </div>
                </div>
            )}
        </>
    )
}

export default Form
