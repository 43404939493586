import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'
import 'primeflex/primeflex.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import Form from './pages/Form/Form'
import { getData } from './service/api'
import { formatedPath } from './service/url'
import Page404 from './components/Page404/Page404'

function App() {
    const [data, setData] = useState(null)
    const [is404, setIs404] = useState(false)
    const [code, setCode] = useState(false)
    const [id, setId] = useState(false)
    const [isExtended, setIsExtended] = useState(false)
    const [isQuote, setIsQuote] = useState(false)
    const [color, setColor] = useState(false)
    const [borderColor, setBorderColor] = useState(false)
    const [bgColor, setBgColor] = useState(false)
    const [removeBorder, setRemoveBorder] = useState(false)
    
    const isExclude = () => {
        const exclude = ['/not_found', '/payment']
        let result = false
        exclude.forEach((excludeItem) => {
            if (!result) result = formatedPath() === excludeItem
        })
        return result
    }

    const requestData = (code, type) => {
        if (!isExclude(code)) {
            getData(code, type)
                .then((res) => {
                    if (res?.data.length === 0) {
                        setIs404(true)
                    } else {
                        setIs404(false)
                        setData(res.data.data)
                        document.title = res.data?.data?.title || 'Регистрация на вебинар'  
                    }
                })
                .catch((err) => {
                    if (err.response.status === 404) {
                        setIs404(true)
                    }
                })
        }
    }

    useEffect(() => {
        let url = new URL(window.location.href)
        setCode(url.searchParams.get('code'));
        setId(url.searchParams.get('id'));
        
        const isExtended = !!url.searchParams.get('extended')
        setIsExtended(isExtended);
        const isQuote = !!url.searchParams.get('quote')
        setIsQuote(isQuote);
        const isRemoveBorder = !!url.searchParams.get('removeBorder')
        setRemoveBorder(isRemoveBorder);
        setColor(url.searchParams.get('color') ? url.searchParams.get('color') : false);
        setBgColor(url.searchParams.get('bg') ? url.searchParams.get('bg') : false);
        setBorderColor(url.searchParams.get('border') ? url.searchParams.get('border') : false);
    }, [])

    useEffect(() => {
        if(color) document.documentElement.style.setProperty('--main-color', '#' + color)
    }, [color])

    useEffect(() => {
        if(borderColor) document.documentElement.style.setProperty('--borderColor-color', '#' + borderColor)
    }, [borderColor])

    useEffect(() => {
        if(removeBorder) {
            document.documentElement.style.setProperty('--form-border', 'none')
            document.documentElement.style.setProperty('--form-padding', '0')
        }
    }, [removeBorder])

    useEffect(() => {
        if(bgColor) document.documentElement.style.setProperty('--bgColor-color', '#' + bgColor)
    }, [bgColor])
    

    useEffect(() => {
        code && requestData(code)
        id && requestData(id, true)
        
    }, [id, code])


    return (
        <>
            {is404 && <Page404 />}
            {!is404 && (
                <div className="App ">
                    <Routes>
                        {/* <Route path="/ae/web" element={<Form data={data}  />} /> */}
                        <Route path="/register" element={<Form data={data} isQuote={isQuote} isExtended={isExtended}/>} />
                        {/* <Route path="/date" element={<Form data={data} isQuote={isQuote} isExtended={isExtended}/>} /> */}
                    </Routes>
                </div>
            )}
        </>
    )
}

export default App
