import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './ThankYouLetter.scss'

const ThankYouLetter = ({ data, registrationData }) => {
    const navigate = useNavigate()

    const [time, setTime] = useState(3)
    // const isPayment = data?.is_payment && registrationData.amount.rub > 0
    const isPayment = data?.is_payment
    let interval = null

    // console.log(data?.is_payment)

    useEffect(() => {
        // if (isPayment) {
        //     interval = setInterval(() => {
        //         setTime((time) => time - 1)
        //     }, 1000)
        //     setTimeout(function () {
        //         // navigate(`/payment/?invoice_id=${registrationData?.invoice_id}`)
        //         window.location.href = 'https://asc.gpsys100.com/ideal/thx?email=' + registrationData.email
        //     }, time * 1000)
        // }
        // return () => clearInterval(interval)
        // window.location.href = 'https://asc.gpsys100.com/ideal/thx?email=' + registrationData.email
    }, [])

    return (
        <div className="thankyou-letter lg:pl-4">
            <h2 className="title">Спасибо!</h2>
            <div className="letter">
                {data?.success_message && <p className="letter mb-2">{data?.success_message}</p>}
                {isPayment && (
                    <p>
                        Вы будете перенаправлены на страницу оплаты через <span>{time}</span>...
                    </p>
                )}
            </div>
        </div>
    )
}

export default ThankYouLetter
