import React from 'react'
import './Logo.scss'

const Logo = ({ data }) => {
    return (
        <div className="logo">
            <img alt="logo" src={`https://edu.asc.gpsys100.com/register/assets/logo/logo-${data?.logo || 'gray'}.png`} />
            {data?.company && (
                <div>
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: data?.company || '<span>Александр Шахов</span>',
                        }}
                    ></h3>
                </div>
            )}
        </div>
    )
}

export default Logo
