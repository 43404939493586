import React, { useState } from 'react'
import Marquee from 'react-double-marquee'
import FormComponent from '../../../components/FormComponent/FormComponent'
import Quote from '../../../components/Quote/Quote'
import './FormMini.scss'

const FormMini = ({ data, isQuote, isExtended }) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    return (
        <div className="form-mini col-12">
            <div className="form-mini-container grid">

                <div className={isQuote ? "content col-12 lg:col-7" : ''}>
                    {isQuote && <Quote />}
                </div>

                <div className={isQuote ? "sendForm col-12 lg:col-4 ml-auto" : 'col-12 m-auto'}>
                    <FormComponent data={data} isExtended={isExtended} setIsFormSubmitted={setIsFormSubmitted} />
                    <div className="lg:pl-4">
                        <p className="terms">
                            Нажимая кнопку «Записаться», я принимаю условия публичной оферты, политики конфиденциальности, и даю согласие на обработку персональных данных.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormMini
