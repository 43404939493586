import { Skeleton } from 'primereact/skeleton'

export const FormSkeletons = ({isExtended}) => {
    return (
        <div className="form flex flex-column justify-content-between w-full lg:pr-6 lg:pl-5">
            <div className="grid">
                {!isExtended && <div className="col-12">
                    <Skeleton className="mb-3" width="30%" height="40px"></Skeleton>
                    <Skeleton className="mb-3" width="45%" height="35px"></Skeleton>
                </div> }
                <div className="col-12">
                    <Skeleton className="mb-3" width="100%" height="360px"></Skeleton>
                    <Skeleton width="100%" height="56px"></Skeleton>
                </div>

                {!isExtended && <div className="col-12 lg:col-4 lg:ml-auto">
                    <Skeleton className="mb-3" width="100%" height="360px"></Skeleton>
                    <Skeleton width="100%" height="56px"></Skeleton>
                </div>}
            </div>
        </div>
    )
}
