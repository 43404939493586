export const formatedPath = (host) => {
    let url = new URL(window.location)

    const hostString = host ? url.host : ''; 
    
    if (url.pathname[url.pathname.length - 1] === '/') {
        return hostString + url.pathname.slice(0, -1)
    } else {
        return hostString + url.pathname
    }
}
export const getUtms = () => {
    const data = {}
    let url = new URL(window.location)
    const utms = ['utm_source','utm_campaign','utm_medium','utm_content','utm_term'];
    utms.forEach(utm => data[utm] = url.searchParams.get(utm))
    return data;
}
