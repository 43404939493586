import axios from 'axios'

export const baseUrl = 'https://backend.ashahov.com'
// export const baseUrl = 'https://02c3-217-66-158-133.ngrok.io'

export const getData = (code, type) => {
    if(type === true) return axios.get(`${baseUrl}/api/configs/registration?id=${code}`)
    return axios.get(`${baseUrl}/api/configs/registration?code=${code}`)
}

export const apply = (data) => {
    return axios.post(`${baseUrl}/api/new-apply`, data)
}

export const geo = () => {
    return axios.get('https://api.ipdata.co/?api-key=29f46259903f79a2cfc26fe2634146bf07076bd48c4e8d2616a18c67')
}

export const payment = (applyId) => {
    return axios.post(`${baseUrl}/api/get-apply/`, { apply_id: applyId })
}

export const registration = (userData) => {
    return axios.post(`${baseUrl}/api/registration`, userData)
}
