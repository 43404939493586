import React, { useState } from 'react'
import './Quote.scss'

const Quote = () => {
    const [quotes, setQuotes] = useState([
        <p><b>«Не анализируй это»</b>. Но не призываем не думать вообще. Просто перестаньте тратить свою драгоценную эмоциональную энергию на бесконечные “А что, если…” и “Что это может значить?”, ведь гипотез может быть несметное множество, но никто никогда не сможет предвидеть или предугадать будущее. Опирайтесь только на достоверные факты.'</p>,
        <p>Если у мужчины <b>нет планов на вас в будущем</b>,<br/>значит надо <b>оставить его в прошлом</b>.</p>,
        <p>Мы любим, когда счастливы и счастливы, когда любим.</p>,
        <p>Обида -это эмоция, возникшая от наших собственных мыслей, что нас не ценят так, как <b>мы этого ожидаем</b>.</p>,
        <p>Для счастливых отношений нужно <b>любить, уважать и слушать друг друга</b>.</p>,
        <p>- Какая лучшая месть для обидчика?<br/> - <b>Стать счастливой</b>.</p>,
        <p>Задача, смысл и ответственность каждого человека - сделать свою жизнь счастливой.</p>,
        <p>Лучший мужской парфюм - <b>это ответственность</b>. Добавьте еще нотку юмора, оттенки интеллекта, привкус обаяния и харизмы. Говорят, такой запах сводит женщин с ума.</p>,
        <p>Любовь - это всегда свободный выбор. Именно поэтому мы ценим ее.</p>,
        <p>Сильная личность определяется способностью управлять собой и меняться, а не способностью спорить с другими до потери пульса.</p>,
        <p>Если мужчина все время в телефоне. то и секс он должен видеть только в телевизоре.</p>,
        <p>Делать одно и то же и надеяться на другой результат - это <b>глупо</b>.</p>,
        <p>Любовь как танец: чем ближе друг к другу, тем больше наступаете на ноги. Любви, как и танцу, нужно учиться.</p>,
        <p>Людям легче критиковать, чем поддерживать, Для того, чтобы поддерживать, говорить что-то хорошее нужно иметь большое сердце, запас любви.</p>,
        <p>- Почему вы такой крутой? - Потому что жена кипяток.</p>,
        <p>Не нужно искать серьезные отношения, <b>ищите ответственных мужчин</b>.</p>,
    ])

    const randomQuote = () => {
        let length = quotes.length
        return <p>{quotes[Math.floor(Math.random() * length)]}</p>
    }

    const quoteIcon = () => {
        return { backgroundPosition: 'top 0px left 50%', backgroundRepeat: 'no-repeat', backgroundImage: `url("/assets/icons/star.svg")` }
    }
    return (
        <div className="quote" style={quoteIcon()}>
            <div className="quote-body">
                {/* <p>{quotes[0]}</p> */}
                {randomQuote()}
            </div>
            <div className="quote-footer">
                <div className="img-container">
                    <img alt="painting" src={`/assets/painting.png`} />
                </div>
                <div className="author">
                    Психолог
                    <br /> <span>Александр Шахов</span>
                </div>
            </div>
        </div>
    )
}

export default Quote
