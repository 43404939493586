import React, { useEffect, useState } from 'react'
import Marquee from 'react-double-marquee'
import { Skeleton } from 'primereact/skeleton'
import FormComponent from '../../components/FormComponent/FormComponent'
import { FormSkeletons } from './FormSkeletons'
import Logo from '../../components/Logo/Logo'
import FormMini from './FormMini/FormMini'
import './Form.scss'

const Form = ({ data, isExtended, isQuote }) => {
    const [selectedTariff, setSelectedTariff] = useState(null)
    const [tariffs, setTariffs] = useState(null)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    return (
        <>
            {! isExtended && <div className="flex lg:hidden">
                <Logo data={data} />
            </div>}

            {!data && <FormSkeletons isExtended = {isExtended}/>}
            {data && <FormMini data={data} isQuote = {isQuote} isExtended = {isExtended}/>}

            {! isExtended && <div className="hidden lg:flex">
                <Logo data={data} />
            </div>}
        </>
    )
}

export default Form
